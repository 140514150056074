import React, { useEffect, useState } from "react";

import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MaterialTable from 'material-table';
import is from 'is_js';

import TableIcons from "../molecules/TableIcons";

import AdminService from "../../services/AdminService";

const CompanyAdminListModal = ({ company, open, setOpen }) => {
    const [companyAdmins, setCompanyAdmins] = useState([]);
    const [loading, setLoading] = useState(false);

    const refreshAdmins = () => {
        setLoading(true);
        AdminService.getCompanyAdmins(company.id)
            .then(resp => setCompanyAdmins(resp?.data || []))
            .catch(console.log)
            .finally(() => setLoading(false));
    }

    const addAdmin = (email) => {
        setLoading(true);
        AdminService.createNewAdmin([email], company.id)
            .catch(console.log)
            .finally(() => refreshAdmins());
    }

    useEffect(() => {
        if (open && company.id) {
            refreshAdmins();
        }
    }, [open, company]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                <b>{'Administradores de ' + company.name}</b>
            </DialogTitle>
            <DialogContent>
                <MaterialTable
                    icons={TableIcons}
                    title={''}
                    options={{ pageSize: 10, pageSizeOptions: [10, 25, 50, 100], sorting: true, thirdSortClick: false }}
                    columns={[
                        { title: 'Admin ID', render: b => <div>{`#${b.id}`}</div> },
                        {
                            title: <b>Nombre</b>,
                            field: 'name',
                            render: rowData => <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt={`${rowData.user?.name} ${rowData.user?.lastname || ''}`} style={{ width: 40, borderRadius: '50%', marginRight: 5 }}>{`${rowData.user?.name?.[0] || ''}${rowData.user?.lastname?.[0] || ''}`}</Avatar>
                                <Box>{`${rowData.user?.name || ''} ${rowData.user?.lastname || ''}`}</Box>
                            </Box>,
                            editable: 'never',
                            customSort: (a, b) => {
                                const aName = `${a.user?.name || ''} ${a.user?.lastname || ''}`;
                                const bName = `${b.user?.name || ''} ${b.user?.lastname || ''}`;
                                if (aName < bName) return -1;
                                if (aName > bName) return 1;
                                return 0;
                            }
                        },
                        {
                            title: <b>Email</b>,
                            render: b => b?.email || b?.user?.email,
                        },
                        {
                            title: <b>Teléfono</b>,
                            render: b => b?.user?.phone,
                        },
                    ]}
                    isLoading={loading}
                    data={companyAdmins}
                    actions={[
                        {
                          icon: TableIcons.Add,
                          tooltip: 'Agregar ADMIN',
                          isFreeAction: true,
                          onClick: () => {
                                let mailToAdd = prompt(`Ingresa el mail para agregar como admin:`);
                                if (!mailToAdd) return;
                                if(is.email(mailToAdd)) {
                                    addAdmin(mailToAdd)
                                } else alert('Mail invalido!')
                            }
                        }
                      ]}
                    editable={{
                        onRowDelete: async (oldData) => {
                            try {
                                await AdminService.deleteAdmin(company.id, oldData.id);
                            }
                            catch (error) { console.log(error); }
                            finally {
                                refreshAdmins();
                            }
                        },
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} admins(s) selecionados'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay admins para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtros'
                            },
                            editRow: {
                                deleteText: '¿Desea eliminar este admin?'
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyAdminListModal;
