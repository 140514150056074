import axios from 'axios';

const FeaturesService = {
    getVouchers: () => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-voucher/subscriptionVouchers/list`;
        return axios.get(url)
    },
    updateStatus: (payload) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-voucher/subscriptionVoucher/${payload.subscriptionVoucherId}/update-status?status=${payload.status}`;
        return axios.patch(url)
    },
    createHostService: (payload) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin/host-services/${payload.hostId}/service/${payload.serviceName}`;
        return axios.post(url, payload);
    },

    getFeatureCategoriesOptions: (companyId, companyCountryId) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-feature/company/${companyId}/companyCounty/${companyCountryId}/categories`;
        return axios.get(url)
    },

    getActiveFeatureCategories: (companyId) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-feature/company/${companyId}/companyCounty/features`;
        return axios.get(url)
    },

    updateCategoryLimit: (companyId, payload) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-feature/company/${companyId}/companyCounty/category-limits`;
        return axios.patch(url, payload);
    },

    deleteCategoryLimit: (companyId, payload) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-feature/company/${companyId}/companyCounty/category-limits`;
        return axios.delete(url, { data: payload });
    },

    toggleFeatureByCategoryName: (companyId, hasFeature, categoryName) => {
        const url = `${process.env.REACT_APP_API_FEATURES_URL}/admin-feature/company/${companyId}/toggle-feature/${categoryName}?hasFeature=${hasFeature}`;
        return axios.put(url);
    }
}

export default FeaturesService;
